<template>
    <div class="Swiper" :class="{ m_swiper: $store.state.screenType }">
        <div
            class="swiper-container swipers"
            ref="mySwiper"
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"
        >
            <div class="swiper-wrapper">
                <div
                    class="swiper-slide"
                    v-for="(value, index) in list"
                    :key="index"
                >
                    <a :href="value.SkipUrl">
                        <img :src="imgUrl(baseUrl, value.ImageUrl, '@t:1920x1920>')" alt="">
                    </a>
                </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination show-home"></div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <slot name="navbar"></slot>
        </div>
    </div>
</template>

<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import { imgUrl } from "@/utils/urlutils";
import { mapState } from "vuex";

export default {
    name: "Swiper",
    props: ["list"],
    data() {
        return {
            imgList: [],
        };
    },
    created() {
        //this.imgList = this.list
    },
    mounted() {
        //window.οnresize = () => {
        //    location.reload();
        //}
        //this.$nextTick(function () {
        new Swiper(".swipers", {
            observer: true, //自动初始化swiper
            observeParents: true, //自动初始化swiper
            //自动开始
            autoplay: {
                delay: 2000, //时间间隔
                disableOnInteraction: false, //*手动操作轮播图后不会暂停*
            },
            loop: true, // 循环模式选项
            //分页器
            pagination: {
                el: ".show-home",
                clickable: true,
            },
            //前进后退按钮
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
        //})
    },
    methods: {
        imgUrl,
        mouseEnter() {
            //console.dir(this.$refs.mySwiper)
            this.$refs.mySwiper.swiper.autoplay.stop();
            //console.dir(this.$refs.mySwiper)
        },

        mouseLeave() {
            this.$refs.mySwiper.swiper.autoplay.start();
        },
    },
    computed: {
        ...mapState(["baseUrl"]),
    },
};
</script>


<style lang="scss" scoped>
/*-- -------Swiper---------- --*/
.Swiper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    .swiper-container {
        .swiper-wrapper {
            .swiper-slide {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        /* 分页器  */

        .swiper-pagination {
            right: 11.6875rem;
            left: auto;
            bottom: 6.875rem;
            width: auto;

            .swiper-pagination-bullet {
                width: 2.3125rem;
                height: 0.1875rem;
                border-radius: 0.5rem;
                background: #fff;
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                background-color: red !important;
            }
        }

        /*  左右按钮 */
        .swiper-button-prev {
            width: 2.8125rem;
            height: 1.8125rem;
            left: 10%;
            background-image: url(../assets/banner_prve.png);
            background-size: 2.8125rem 1.8125rem;
        }

        .swiper-button-next {
            width: 2.8125rem;
            height: 1.8125rem;
            right: 10%;
            background-image: url(../assets/banner_next.png);
            background-size: 2.8125rem 1.8125rem;
        }
    }
}

@import "css/m-swierCom";
</style>
